<template>
	<div class="tr-main">
		<htCnQueryCard @queryHtCn="queryHtCn" @chooseCcsqd="chooseCcsqd"></htCnQueryCard>

		<el-container>
			<el-main class="center-main">
				<div class="filter all-container">
					<h3>筛选</h3>
					<el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="价格/星级" name="0">
							<span slot="label"
								:class="{ spot: (htCnSonTags.start.length || htCnSonTags.input.length) > 0 ? true : false }"></span>
							<span slot="label">价格/星级</span>
							<ht-start ref="start" @getInputObj="getInputObj" @changeList="changeList" @removeAll="removeAll"></ht-start>
						</el-tab-pane>
						<el-tab-pane>
							<span slot="label"
								:class="{ spot: (htCnSonTags.hotel.length || htCnSonTags.services.length) > 0 ? true : false }"></span>
							<span slot="label">更多筛选</span>
							<htBusiness :type="2" :name="'品牌连锁'" @changeList="changeList" ref='hotel'></htBusiness>
							<htBusiness :type="3" :name="'设施服务'" @changeList="changeList"></htBusiness>
							<el-button round v-if="tripType == 1"   :class="{clickbtn: travelStandardsFilter }" @click="changetravelStandardsFilter()" >
								符合差标
							</el-button>
						</el-tab-pane>
					</el-tabs>
				</div>
				<div class="bottom all-container">
					<div class="bottom-left">
						<div class="bottom-left-top">
							<h2 v-if="htCnParams.cityId && searchList.list">找到{{ searchList.total }}家</h2>
							<h2 v-if="htCnParams.cityId">酒店</h2>
							<div class="search-list">
								<el-tag v-for="tag in tagList" :key="tag.name" closable size="medium" effect="dark"
									@close="closeTag(tag)">
									{{ tag.name }}
								</el-tag>
								<span class="removeAll" v-if="tagList.length" @click="removeAll">全部清除</span>
							</div>
							<div class="search-title">
								<el-select v-model="sortModel" @change="sortChange" placeholder="默认排序">
									<el-option v-for="item in sortOptions" :key="item.value" :label="item.name" :value="item.value">
									</el-option>
								</el-select>
							</div>
						</div>
						<div calss="bottom-left-bottom">
							<htCnList class="bottom-left-bottom" :searchList="searchList" :loading="htLoading" :secondList="secondList"
								@getMapAddress="getMapAddress" @jumpDetails="jumpDetails"></htCnList>
							<el-pagination v-if="searchList.total && (!secondList || !secondList.total)"
								@current-change="handleCurrentChange" background layout="prev, pager, next"
								:total="searchList.total" :current-page="htCnParams.pageNum"></el-pagination>
							<el-pagination v-if="secondList.total" @current-change="handleCurrentChange" background
								layout="prev, pager, next" :current-page="htCnParams.pageNum" :total="secondList.total"></el-pagination>
						</div>
					</div>
					<div class="bottom-right">
						<el-popover placement="bottom" width="200" trigger="hover">
							<slot name="content" v-if="tripType != 2">
								<!-- 申请单管控-->
								<span class="cb-color" v-if="gkfsRole == 1"
									style="color: #f70; font-size: 12px; margin-top: 5px; letter-spacing: 1px; font-weight: 100;">
									预算金额{{ fyys }}
								</span>
								<!-- 差标管控 或者 单订模式-->
								<span class="cb-color" v-if="gkfsRole == 2 || bookStyle == 1"
									style="color: #f70; font-size: 12px; margin-top: 5px; letter-spacing: 1px; font-weight: 100;">
									酒店订房不超过{{ jdjgbz }}元/间
								</span>
								<!-- 差标管控 -->
								<div class="ccsqdPe" v-if="JSON.stringify(policy) != '{}' && ((bookStyle == 2 && gkfsRole == 2 && tripType == 1) || (bookStyle == 1 && tripType == 1))"
									slot="reference">
									<div class="clbz-clbzText">
										<span>{{ policy.mulPerson == 1 ? '已开启' : '未开启' }}多人差标模式</span>
									</div>
									<div class="clbz-user" v-show="policy.mulPerson != 1">
										本次预订采用<span style="opacity: 1;color: #4089FF;">{{ useNameclbz }}</span>的差旅标准
									</div>
									<div class="clbz-user" v-show="policy.mulPerson == 1">
										本次预订采用<span style="opacity: 1;color: #4089FF;">入住人{{ policy.mulPersonStandardDesc }}</span>的差旅标准
									</div>
                  <span class="f12" v-if="jdjgbz">酒店订房 不超过 <span style="color: red; font-size: 14px;font-weight: 400;">{{jdjgbz}}元</span>/间</span>
								</div>
								<!-- 申请单管控 -->
								<div class="ccsqdPe" v-if="bookStyle == 2 && gkfsRole == 1 && tripType == 1" slot="reference">
									<span>本次预算金额</span>
									<span style="padding: 0 1px;color:#4089FF;">{{ fyys }}</span>
									<span>的差旅标准</span>
								</div>
							</slot>
							<slot name="content" v-if="tripType == 2">
								<div class="ccsqdPe" slot="reference">当前预算未管控</div>
							</slot>
						</el-popover>
						<ht-cn-amap :class="{ mapFixed: fixed }" @windowsClick="windowsClick" :zoomInAndOut="zoomInAndOut"
							:mapType="mapType" :currentMap="currentMap" :searchList="searchList"></ht-cn-amap>
					</div>
				</div>
			</el-main>
		</el-container>
		<!-- 单订模式 出行人-->

	</div>
</template>

<script>
import {
mapGetters, mapMutations, mapState
} from 'vuex'
import mixin from './../../mixin'
import hotelmixin from './../../mixin/models/hotelMixin.js'
import htBusiness from './HtCnComponent/htBusiness'
import htCnAmap from './HtCnComponent/htCnAmap.vue'
import htCnList from './HtCnComponent/htCnList'
import htCnQueryCard from './HtCnComponent/htCnQueryCard'
import htStart from './HtCnComponent/htStart'
export default {
	created() { },
	mounted() {
		// 首页跳转搜索一下
		let isHome = this.$route.query.home;
		if (isHome) {
			this.searchAll()
		}
		this.initRulers()
		window.onscroll = () => {
			let top = this.getScrollTop()
			if (top > 496) {
				this.fixed = true;
			} else {
				this.fixed = false;
			}
		}

	},
	components: {
		// htCnSearch,
		htBusiness,
		htStart,
		htCnList,
		'ht-cn-amap': htCnAmap,
		htCnQueryCard
	},
	mixins: [mixin.ccsqdMixin,hotelmixin.hotelCxrListCtrl],
	data() {
		// 限制数量（小于才加载分屏）
		this.minSecondLength = 8;
		return {
			cityId: '',
			mapType: 1,
			fyys: '',
			htLoading: false,
			searchList: {},
			tagList: [],
			activeName: '',
			sortModel: '',
			fixed: false, // 地图定位
			zoomInAndOut: false, //点击文案地图放大缩小
			currentMap: {}, //点击列表获取当前酒店新增地址 及 坐标定位
			sortOptions: [
				//sort排序
				{
					name: '默认排序',
					value: ''
				},
				{
					name: '星级从高到低',
					value: 22
				},
				{
					name: '星级从低到高',
					value: 21
				},
				{
					name: '价格从低到高',
					value: 31
				},
				{
					name: '价格从高到低',
					value: 32
				},
			],
			targetGeo: null,
			secondList: {},
			travelStandardsFilter:false,
			
		}
	},
	watch: {
		searchList: {
			handler(val) {
				if (!val.list || val.list.length == 0) {
					// 数据为空的时候重置页码
					this.SET_HTCN_PARAMS({
						pageNum: 1,
					})
				}
			},
		},
		htCnSonTags: {
			handler(val) {
				this.tagList = []
				for (let key in val) {
					val[key].forEach((element) => {
						this.tagList.push(element)
					})
				}
			},
			deep: true,
			immediate: true,
		},
	},
	computed: {
		...mapState({
			htCnQuery: (state) => state.htCn.htCnQuery, //
			htCnCcsqD: (state) => state.htCn.htCnCcsqD,
			ccsqdInfo: (state) => state.common.ccsqdInfo, // 出差申请单信息
			htCnParams: (state) => state.htCn.htCnParams,
			htCnSonTags: (state) => state.htCn.htCnSonTags,
			htCnPolicy: (state) => state.htCn.htCnPolicy,
			cxrList: state => state.htCn.cxrList, // 出行人
		}),
		...mapGetters({
			getVipParamterByParNo: 'common/getVipParamterByParNo',
		}),
		// 日期最小，和最大值对象
		bookDateRange() {
			// 方法来自于mixins
			let dateRangeObj = this.getBookDateRange(10903, this.htCnQuery.tripType, 365, this.htCnCcsqD.ccsqdId, this.htCnCcsqD.routeId);

			return dateRangeObj
		},
		ccr() {
			return this.htCnCcsqD.detail.ccr
		},
		// 出差人
		useNameclbz() {
			return this.htCnQuery.useNameclbz;
		},
		jdjgbz() {

			return this.htCnPolicy?.policy?.jdjgbz ?? ''

		},
		policy() {
			return this.htCnPolicy?.policy ?? {}
		},
		tripType() {
			/* 默认当前因公因私*/
			return this.htCnQuery.tripType //1为公 2为私
		},
		gkfsRole() {
			return this.htCnQuery.gkfsRole
		},
		// fyys() {
		// 	return this.htCnCcsqD?.currentRoute?.fyys??''
		// },
		userInfo() {
			let user = this.$common.getUserInfo() || {};
			return user
		},
		vipCorp() {
			return this.userInfo.vipCorp || {};
		},
		bookStyle() {
			// 1单订模式  2出差申请单模式
			return this.$common.getBookStyle(this.$store,10903) || this.vipCorp.bookStyle
		},
		//因公因私参数控制
		vipParams() {
			/**
			 *   t系统   1因私开启 2因公开启
			 *   et  1因公开启，2因私开启  这里做一下换
			 */
			let params = this.getVipParamterByParNo(20009, 3)
			return params //  与t系统参数相反 //this.$common.getVipParams('20011',3);  火车票因公 ， 因私 开启
		},

	},


	methods: {
		...mapMutations({
			SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY', // 保存因共模式下的自定义信息
			SET_HTCN_PARAMS: 'htCn/SET_HTCN_PARAMS', // 设置查询参数
			SET_HTCN_CCSQD: 'htCn/SET_HTCN_CCSQD', //保存因共模式下的出差申请单信息
			SET_CCSQD_INFO: 'common/setCcsqdInfo', // 全局的出差申请单信息
			SET_HTCN_SONNTAGS: 'htCn/SET_HTCN_SONNTAGS',
			SAVE_HOTELINFO: 'htCn/SAVE_HOTELINFO',
		}),
		windowsClick(item) {
			let itemInfo = this.searchList.list.filter((i) => i.jdid == item)
			this.jumpDetails(itemInfo[0])
		},
		chooseCcsqd(value) {
			this.fyys = value?.currentRoute?.fyys ?? ''
		},
		// 获取滚动条到顶部距离
		getScrollTop() {
			let scrollTop = 0;
			if (document.documentElement && document.documentElement.scrollTop) {
				scrollTop = document.documentElement.scrollTop;
			} else if (document.body) {
				scrollTop = document.body.scrollTop;
			}
			return scrollTop;
		},
		getInputObj() {
			this.searchAll(true)
		},
		handleCurrentChange(val) {
			// this.htCnParams.pageNum=val
			this.SET_HTCN_PARAMS({
				pageNum: val,
			})
			this.searchAll()
		},
		changeList() {
			this.searchAll(true)
		},
		// 符合差标
		changetravelStandardsFilter(){
			this.travelStandardsFilter = !this.travelStandardsFilter;
			
			this.searchAll(true)
		},
		jumpDetails(item) {
			item.checkInDate = this.htCnParams.checkInDate
			item.checkOutDate = this.htCnParams.checkOutDate
			let hotelItem = {
				checkInDate: this.htCnParams.checkInDate,
				checkOutDate: this.htCnParams.checkOutDate,
				jdid: item.jdid,
				cityId: this.htCnParams.cityId
			}
			this.SAVE_HOTELINFO(item)
			let routeUrl = this.$router.resolve({
				name: 'HtCnDetails',
				query: hotelItem,
			})
			window.open(routeUrl.href, '_blank')
		},
		// 地图位置
		getMapAddress(item) {
			this.zoomInAndOut = this.zoomInAndOut ? false : true;
			this.currentMap = item;
		},
		// 查询酒店
		queryHtCn(isClear = false) {
			this.searchAll(isClear)
		},
		sortChange() {
			// 排序
			this.SET_HTCN_PARAMS({
				sort: this.sortModel
			})
			this.searchAll(true)
		},
		closeTag(val) {
			// 关闭单个tages
			if (val.fitlerTypeByOne == 'start') {
				let index = this.$refs.start.startList.indexOf(val)
				this.$refs.start.startList[index].bOn = false
			} else if (val.fitlerTypeByOne == 'input') {
				this.$refs.start.form.min = ''
				this.$refs.start.form.max = ''
				this.$refs.start.priceBlur()
			} else if (val.fitlerTypeByOne == 'hotel') {
				this.$refs.hotel.removeHotel(val)
			} else if (val.fitlerTypeByOne == 'services') {
				this.$refs.hotel.removeServices(val)
			}
		},
		removeAll() {
			this.tagList = []
			this.$refs.hotel.removeAll()
			this.SET_HTCN_SONNTAGS({
				start: [],
				input: [],
				hotel: [],
				services: [],
			})
			this.SET_HTCN_PARAMS({
				starRate: '',
				priceRange: '',
				propertyIds: '',
				brandIds: '',
			})
			// 清除数据
			this.searchAll(true)
		},
		initRulers() {
			// 初始化的时候就把管控规则存入vux里面
			// 出差审请单模式
			if (this.bookStyle == 2) {
				//1单订模式  2出差申请单模式
			
			} else {
				// this.getClbz();
			}
		},

		handleClick(tab, event) {
			if (tab.index == this.activeName) {
				// this.spotShow = true
			}
		},
		judgeQueryParam() {
			let htCnParams = this.htCnParams;
			if (!htCnParams.cityId) {
				this.$message({
					type: 'error',
					message: '请先输入城市',
				})
				return;
			}
			if (!htCnParams.checkInDate || !htCnParams.checkOutDate) {
				this.$message({
					type: 'error',
					message: '请先输入日期',
				})
				return;
			}

			let params = {
				brandIds: htCnParams.brandIds, //品牌id，多个以逗号隔开 ,
				cityId: htCnParams.cityId, //城市或者商圈id
				checkInDate: htCnParams.checkInDate, //日期开始时间
				checkOutDate: htCnParams.checkOutDate, // 日期结束时间
				businessZone: htCnParams.businessZone, //商圈id，searchType=2时，必传 ,
				propertyIds: htCnParams.propertyIds, //服务设施
				district: htCnParams.district, // 行政区ID
				keywords: htCnParams.keywords, //关键字
				lat: htCnParams.lat,
				lon: htCnParams.lon,
				sort: htCnParams.sort, //排序
				jdType: htCnParams.jdType, //是否是协议
				pageNum: htCnParams.pageNum, //当前页数，不能小于1 ,
				propertyIds: htCnParams.propertyIds, //服务设施id，多个以逗号隔开 ,
				priceRange: htCnParams.priceRange, //价格范围：不传代表不限；范围以-隔开；如果是150元以下，传0-150；如果是800元以上，传800-999999 ,
				searchType: htCnParams.searchType, //查询类型，1 按行政区；2 按商圈；3 按经纬度；4 指定酒店附近；默认 0 ,
				starRate: htCnParams.starRate, //挂牌星级：不限就不传；二星级以下,传2；三星传3A；以此类推,多个以英文逗号隔开 ,
				keywords: htCnParams.keywords, //酒店名称、或酒店地址中包含的关键字
				count: 10,
			}
			if (!!params.lat && !!params.keywords) {
				params.poiValue = params.keywords;
			}

			// if (!!params.keywords) {
			// 	params.poiValue = params.keywords;
			// }

			if (!!params.district) {
				params.poiValue = htCnParams.districtName;
			}
			if (!!params.businessZone) {
				params.poiValue = htCnParams.businessZoneName;
			}

			if(this.travelStandardsFilter && this.tripType == 1){
				params.travelStandardsFilter = this.travelStandardsFilter?1:0;

				let clbzObj = this.getClbzParamMap();
					params = {
						...params,
						...clbzObj
					}
			
					params.ccsqdId = this.htCnCcsqD.ccsqdId;
					params.routeId = this.htCnCcsqD.routeId;
			}


			return params;
		},
		searchAll(isClear = false) {
			if (!!isClear) {
				this.SET_HTCN_PARAMS({
					pageNum: 1,
				})
				this.secondList = {};
			}
			if (
				this.htCnParams.keywords &&
				this.searchList &&
				this.searchList.list &&
				this.searchList.list.length <= this.minSecondLength &&
				this.secondList &&
				this.secondList.list &&
				this.secondList.list.length > 0
			) {
				this.getCompensateesList();
				return;
			}

			this.htLoading = true
			let params = this.judgeQueryParam()
			if (!params) return;

			this.$conn.getConn('htbook.hotelListSearch2', {
				jsfile: false
			})(params).then((res) => {
				if ((res.httpStatus = 200)) {
					let data = res.data || {};
					let pageInfo = data.pageInfo || {};
					var list = pageInfo.list || [];
					this.htLoading = false;

					if (params.pageNum == 1 && list.length > 0 && list.length < this.minSecondLength) {
						this.htLoading = true;
					}

					if (list.length > 0) {
						pageInfo.list.map((item) => {
							let arr = [];
							if (item.facilities) {
								arr = item.facilities.split(",");
								for (var i = 0; i < arr.length; i++) {
									// i - 1 ,因为空元素在数组下标 2 位置，删除空之后，后面的元素要向前补位，
									// 这样才能真正去掉空元素,觉得这句可以删掉的连续为空试试，然后思考其中逻辑
									if (!arr[i]) {
										arr.splice(i, 1);
										i = i - 1;
									}
								}
							}
							item.facilities = arr;
							return item
						})
					}
					this.searchList = pageInfo;
					this.targetGeo = data.targetGeo;
					if (params.pageNum == 1 && list.length > 0 && list.length <= this.minSecondLength) {
						this.getCompensateesList();
					}
				}
			})
				.catch((err) => {
					this.$message({
						type: 'error',
						message: err.tips,
					})
					this.htLoading = false
				})
		},
		getCompensateesList() {
			this.htLoading = true;
			var params = this.judgeQueryParam();
			if (!params) return;
			let list = this.searchList.list || [];
			params.masterHotelId = list.map(item => item.jdid).join(',');
			params.lat = this.targetGeo ? this.targetGeo.lat : '';
			params.lon = this.targetGeo ? this.targetGeo.lng : '';
			params.targetName = this.targetGeo ? this.targetGeo.name : '';

			var hotelListCompensatees = this.$conn.getConn('htbook.hotelListCompensate');
			hotelListCompensatees(params, (res) => {
				this.htLoading = false;
				let data = res.data || {};
				let pageInfo = data.pageInfo || {};
				if (pageInfo.list.length > 0) {
					pageInfo.list.map((item) => {
						let arr = [];
						if (item.facilities) {
							arr = item.facilities.split(",");
							for (var i = 0; i < arr.length; i++) {
								// i - 1 ,因为空元素在数组下标 2 位置，删除空之后，后面的元素要向前补位，
								// 这样才能真正去掉空元素,觉得这句可以删掉的连续为空试试，然后思考其中逻辑
								if (!arr[i]) {
									arr.splice(i, 1);
									i = i - 1;
								}
							}
						}
						item.facilities = arr;
						return item
					})
				}
				this.secondList = pageInfo;
			}, (err) => {
				this.htLoading = false;
			})
		},
	},
}
</script>

<style scoped lang="scss">
.f12{
  font-size: 14px;
  font-weight: 400;
}
.clickbtn {
  border: 1px solid #007aff;
  background-color: #007aff;
  color: #fff;

}
.clickbtn.el-button:focus, .clickbtn.el-button:hover {
    color: #fff;
	border: 1px solid #007aff;
  	background-color: #007aff;
}
.tr-main {
	width: 100%;
	margin: 0 auto;
	min-height: 860px;
	font-family: '微软雅黑';

	.all-container {
		width: 1200px;
		margin: 0 auto;
	}

	.center-main {
		height: 100%;
		min-height: 860px;
		background-color: #eef1f6;

		.bottom {
			background-color: white;
			margin-bottom: 10px;
			display: flex;
			justify-content: space-between;
			// align-items: center;
			background-color: #eef1f6;

			.bottom-left {
				width: 860px;

				.bottom-left-top {
					background-color: #fff;
					box-sizing: border-box;
					padding: 16px 16px 16px 24px;
					display: flex;

					h2 {
						margin-top: 5px;
					}

					.search-list {
						flex: 1;
						margin-left: 24px;
						display: flex;
						width: 300px;
						flex-wrap: wrap;
						align-items: center;

						.el-tag--medium {
							margin-right: 10px;
							margin-top: 10px;
						}

						.removeAll {
							color: #007aff;
							font-size: 12px;
							margin-left: 20px;
							margin-top: 10px;
						}
					}

					.search-title {
						margin-top: 5px;
						margin-right: 5px;

						/deep/ .el-input__inner {
							border: 0;
							width: 126px;
							//  color: red;
						}
					}
				}
			}

			.bottom-right {
				background-color: #eef1f6;
				flex: 1;
				margin-left: 20px;
				height: 400px;

				.mapFixed {
					position: fixed;
					top: 0;
					width: 320px;
					height: 400px;
				}

				.ccsqdPe {
					width: 100%;
					min-height: 69px;
					background-color: #fff;
					margin-bottom: 20px;
					box-sizing: border-box;
					cursor: pointer;
					padding: 20px 10px;

					span {
						font-size: 15px;
						font-weight: bold;
						color: #424242;
						// line-height: 69px;
					}

					// }
				}
			}
		}
	}

	.tr-main-header {
		display: flex;
		justify-content: flex-start;
		// padding-top: 20px;
		height: 70px;

		.el-input {
			width: 200px;
			margin-left: 20px;
		}

		.tr-input {
			line-height: 70px;

			/deep/ .el-input__inner {
				width: 200px;
				height: 34px;
				line-height: 34px;
			}
		}
	}

	.bgWhite {
		background-color: #ffffff;
	}

	.filter {
		display: flex;
		justify-content: space-between;
		background-color: white;
		margin-top: 10px;
		margin-bottom: 10px;
		box-sizing: border-box;
		padding: 10px;
		border-radius: 6px;

		h3 {
			margin: 10px;
		}

		.tabs {
			flex: 1;
		}

		.spot {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			display: inline-block;
			margin-right: 5px;
			background: #007aff;
		}

		/deep/ .el-tabs__item {
			display: flex;
			align-items: center;
		}

		/deep/ .el-tabs__nav {
			display: flex;
		}

		/deep/ .el-tab-pane {
			display: flex;
			align-items: center;
		}
	}
}</style>
